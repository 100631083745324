import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import { Box, Columns, Container, Element, Heading, Icon, Loader, Section } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faThumbsUp, faTrophy } from '@fortawesome/free-solid-svg-icons'
import SingleProductThumbnail from '../../components/e-commerce/single-product-thumbnail'
import { useSession } from '../../stores/session'
import TurnoverTargets from '../../components/dashboard/turnover-targets'
import { useTranslation } from 'react-i18next'
import { sortBy } from 'lodash'

const DashboardPage = () => {
  const [sessionState, sessionActions] = useSession()
  const { t } = useTranslation('dashboard')

  React.useEffect(() => {
    sessionActions.getOrganization()
    sessionActions.getOrganizationStats()
    if (sessionState.currentOrganizationTurnover === undefined)
      sessionActions.getOrganizationTurnover()
    //ordersActions.fetch()
  }, [])

  const organization = React.useMemo(() =>
    sessionState.currentOrganization && sessionState.currentOrganization.organization
  , [sessionState.currentOrganization])

  const [lastAchievedTarget, nextTarget] = React.useMemo(() => {
    if (!organization) return []
    const turnoverTargets = sortBy(organization.turnoverTargets, 'target')
    let lastAchievedTargetIndex
    turnoverTargets.forEach((target, index) => {
      if (sessionState.currentOrganizationTurnover && target.target <= sessionState.currentOrganizationTurnover)
        lastAchievedTargetIndex = index
    })
    return [
      turnoverTargets[lastAchievedTargetIndex],
      turnoverTargets[lastAchievedTargetIndex >= 0 ? lastAchievedTargetIndex + 1 : 0]
    ]
  }, [organization, sessionState.currentOrganizationTurnover])

  const favoritesProducts = React.useMemo(() => {
    if (sessionState.currentOrganizationStats && sessionState.currentOrganizationStats.products)
      return sessionState.currentOrganizationStats.products.slice(0, 5)
    return []
  }, [sessionState.currentOrganizationStats])

  console.log(favoritesProducts)

  return (
    <Layout pro logged>
      {
        organization ?
          <>
            {
              sessionState.currentOrganizationRole !== 'organization/commercials' &&
                <Section>
                  <Container>
                    <Heading size={5} mb={5} textColor='dark' textWeight='normal'>
                      {organization.name} - {t('title')} {new Date().getFullYear()}
                    </Heading>
                    <Columns gap={6}>
                      <Columns.Column narrow>
                        <TurnoverTargets organization={organization} turnover={sessionState.currentOrganizationTurnover} />
                      </Columns.Column>
                      <Columns.Column>
                        <Box textAlign='center' pb={6} backgroundColor='success-light'>
                          <Icon textColor='success' textSize={3} mt={5} mb={6}>
                            <FontAwesomeIcon icon={faCheck} />
                          </Icon>
                          <Heading size={7} mb={3} textColor='dark' textWeight='normal' textTransform='uppercase'>
                            {t('discount_guaranteed')}
                          </Heading>
                          <Element textWeight='bold'>
                            { lastAchievedTarget && organization.mafactData ? Math.round(organization.mafactData.turnover / 100 * lastAchievedTarget.discount) : 0 } €
                          </Element>
                        </Box>
                      </Columns.Column>
                      <Columns.Column>
                        <Box textAlign='center' pb={6}>
                          <Icon textColor='warning' textSize={3} mt={5} mb={6}>
                            <FontAwesomeIcon icon={faTrophy} />
                          </Icon>
                          <Heading size={7} mb={3} textColor='dark' textWeight='normal' textTransform='uppercase'>
                            { nextTarget ? t('next_target') : t('goal') }
                          </Heading>
                          <Element>
                            {
                              !sessionState.currentOrganizationTurnover ?
                                <Loader style={{ fontSize: 30, margin: 'auto' }}/> :
                                nextTarget && organization.mafactData ? `${Math.round(nextTarget.target - sessionState.currentOrganizationTurnover)} €` : <FontAwesomeIcon icon={faThumbsUp} />
                            }
                          </Element>
                        </Box>
                      </Columns.Column>
                    </Columns>
                  </Container>
                </Section>
            }
            {
              favoritesProducts[0] &&
                <Section mb={6}>
                  <Container>
                    <Heading size={5} mb={5}>{t('favorites_products')}</Heading>
                    <Columns>
                      {favoritesProducts.map(product =>
                        <Columns.Column size={2} key={product._id}>
                          <SingleProductThumbnail product={product} noPrices/>
                        </Columns.Column>
                      )}
                    </Columns>
                  </Container>
                </Section>
            }
          </>
          : <></>
      }
    </Layout >
  )
}
export default DashboardPage


export const query = graphql`
  query($language: String!) {
          locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
          node {
          ns
          data
          language
        }
      }
    }
  }
`