import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Element, Icon, Loader, Progress, Table } from 'react-bulma-components'
import { faCaretLeft, faCaretRight, faCheck, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { sortBy } from 'lodash'

const TurnoverTargetRow = ({ target, children, turnover }) => (
  <Element
    key={target._id}
    renderAs='td'
    textAlign='right'
    backgroundColor={ target.isAchieved && 'success-light' }
  >
    <Element renderAs='span' textWeight={ target.isAchieved && 'bold' }>
      { children }
    </Element>
  </Element>
)


const TurnoverTargets = ({
  organization,
  turnover
}) => {
  const { t } = useTranslation('dashboard')
  const [displayedNextTargetIndex, setDisplayedNextTargetIndex] = React.useState(0)

  const [achievedTargets, notAchievedTargets, allTargets, displayedTargets] = React.useMemo(() => {
    const achievedTargets = []
    const notAchievedTargets = []
    if (turnover !== undefined) {
      sortBy(organization.turnoverTargets, 'target').forEach((turnoverTarget, index) => {
        const sanitized = {
          ...turnoverTarget,
          isAchieved: organization.mafactData && turnoverTarget.target <= turnover,
          percentage: organization.mafactData && Math.round(turnover / turnoverTarget.target * 100),
          step: index
        }
        if (sanitized.isAchieved)
          achievedTargets.push(sanitized)
        else
          notAchievedTargets.push(sanitized)
      })
    }

    const displayedTargets = []
    if (achievedTargets[0])
      displayedTargets.push(achievedTargets[achievedTargets.length - 1])
    if (notAchievedTargets[0])
      displayedTargets.push(notAchievedTargets[displayedNextTargetIndex])

    return [
      achievedTargets,
      notAchievedTargets,
      [...achievedTargets, ...notAchievedTargets],
      displayedTargets
    ]
  }, [organization, displayedNextTargetIndex, turnover])

  if (turnover === undefined)
    return <Loader mt={5} style={{ fontSize: 30 }}/>

  if (!organization.mafactData)
    return <></>

  return (
    <Table.Container>
      <Table hoverable bordered={false}>
        <thead>
          <tr>
            <td><strong>{turnover} €</strong></td>
            {
              displayedTargets.map((target, index) =>
                <Element textAlign='right' renderAs='td' backgroundColor={ target.isAchieved } key={target._id} style={{ minWidth: 120 }}>
                  <Element renderAs='span' className='icon-text'>
                    {
                      !target.isAchieved && notAchievedTargets[displayedNextTargetIndex - 1] &&
                        <a onClick={ () => setDisplayedNextTargetIndex(displayedNextTargetIndex - 1) }>
                          &nbsp;<FontAwesomeIcon icon={ faCaretLeft } />
                        </a>
                    }
                    <Icon textColor={ target.isAchieved ? 'success' : 'warning' }>
                      <FontAwesomeIcon icon={ target.isAchieved ? faCheck : faTrophy } />
                    </Icon>
                    <Element textWeight={ target.isAchieved && 'bold' }>
                      { `${target.step + 1} / ${organization.turnoverTargets.length}` }
                      {
                        !target.isAchieved && notAchievedTargets[displayedNextTargetIndex + 1] &&
                          <a onClick={ () => setDisplayedNextTargetIndex(displayedNextTargetIndex + 1) }>
                            &nbsp;<FontAwesomeIcon icon={ faCaretRight } />
                          </a>
                      }
                    </Element>
                  </Element>
                </Element>
              )
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Element renderAs='span'>
                {t('targets.target')}
              </Element>
            </td>
            {
              displayedTargets.map((target, index) =>
                <TurnoverTargetRow
                  key={target._id}
                  target={ target }
                >
                  { target.target } €
                </TurnoverTargetRow>
              )
            }
          </tr>
          <tr>
            <td>
              <Element renderAs='span'>
                {t('targets.turnover')}
              </Element>
            </td>

            {
              displayedTargets.map((target, index) =>
                <TurnoverTargetRow
                  key={target._id}
                  target={ target }
                >
                  { target.isAchieved && '+'}
                  {`${(turnover - target.target).toFixed(2)} €`}
                </TurnoverTargetRow>
              )
            }
          </tr>
          <tr>
            <td>
              <Element renderAs='span'>
                {t('targets.discount')}
              </Element>
            </td>
            {
              displayedTargets.map((target, index) =>
                <TurnoverTargetRow
                  key={target._id}
                  target={ target }
                >
                  { target.discount && `${target.discount} %`}
                </TurnoverTargetRow>
              )
            }
          </tr>
          <tr>
            <td>{t('targets.discount_estimation')}</td>
            {
              displayedTargets.map((target, index) =>
                <TurnoverTargetRow
                  key={target._id}
                  target={ target }
                >
                  {
                    target.discount && target.isAchieved &&
                    `${
                      (turnover -
                        (turnover * (1 - (target.discount / 100)))
                      ).toFixed(2)} €`
                  }
                  {
                    target.discount && !target.isAchieved &&
                    `> ${
                      (target.target -
                        (target.target * (1 - (target.discount / 100)))
                      ).toFixed(2)} €`
                  }
                </TurnoverTargetRow>
              )
            }
          </tr>
          <tr>
            <td>
              <Element renderAs='span'>
                {t('targets.target_reached')}
              </Element>
            </td>
            {
              displayedTargets.map((target, index) =>
                <TurnoverTargetRow
                  key={target._id}
                  target={ target }
                >
                  {target.percentage} %
                </TurnoverTargetRow>
              )
            }
          </tr>
          <tr>
            <td></td>
            {
              displayedTargets.map((target, index) => {
                if (!target.target)
                  return <Element></Element>
                return (
                  <Element renderAs='td' backgroundColor={ target.isAchieved && 'success-light' } key={ target._id }>
                    <Progress color={ target.isAchieved ? 'success' : 'warning' } size='small' value={target.percentage} max={100} />
                  </Element>
                )
              })
            }
          </tr>
        </tbody>
      </Table>
    </Table.Container>
  )
}

export default TurnoverTargets